import React from 'react'
import {
    View,
    StyleSheet,
    TouchableOpacity,
    SafeAreaView,
    Dimensions,
    ScrollView,
} from 'react-native'

import { MaterialCommunityIcons } from '@expo/vector-icons';

type ClosableComponentProps = {
    children?: React.ReactNode
    onClose: (() => void) | undefined
}

const MAX_WIDTH = 770;
const windowWidth = Dimensions.get('window').width;

const ClosableComponent: React.FC<ClosableComponentProps> = React.memo(({ onClose, children }) => {
    return (
        <SafeAreaView style={styles.outerContainer}>
            <View style={[styles.container, { maxWidth: MAX_WIDTH, width: windowWidth }]}>
                {onClose && (
                    <View style={styles.header}>
                        <TouchableOpacity onPress={onClose}>
                            <MaterialCommunityIcons name="close" size={45} color="black" />
                        </TouchableOpacity>
                    </View>
                )}
                <ScrollView
                    style={styles.scrollView}
                    contentContainerStyle={styles.scrollViewContent}
                    bounces={false}
                    showsVerticalScrollIndicator={true}
                >
                    <View style={styles.content}>
                        {children}
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    )
})

export default ClosableComponent

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexGrow: 1,
        backgroundColor: '#fff',
        alignSelf: 'center',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        elevation: 3,
        borderRadius: 12,
        overflow: 'hidden',
        marginVertical: 20
    },
    outerContainer: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.1)',
    },
    innerContainer: {
        flex: 1,
        alignItems: 'center',
    },
    header: {
        justifyContent: 'center',
        alignItems: 'flex-end',
        height: 50,
        margin: 10,
    },
    content: {
        flex: 1,
    },
    scrollView: {
        flex: 1,
    },
    scrollViewContent: {
    },
});
