import { View, Text, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AppRootStackParamList } from '../../types';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

export default function Footer() {
    const navigation = useNavigation<NativeStackNavigationProp<AppRootStackParamList>>();

    return (
        <View style={{ backgroundColor: '#f8f9fa', padding: 20, marginTop: 'auto' }}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', gap: 20 }}>
                <TouchableOpacity onPress={() => navigation.navigate("Privacy")}>
                    <Text style={{ color: '#6c757d' }}>Privacy Policy</Text>
                </TouchableOpacity>
                <Text style={{ color: '#6c757d' }}>•</Text>
                <TouchableOpacity onPress={() => window.location.href = 'mailto:contact@foodsmarts.ca'}>
                    <Text style={{ color: '#6c757d' }}>Contact Us</Text>
                </TouchableOpacity>
                <Text style={{ color: '#6c757d' }}>•</Text>
                <Text style={{ color: '#6c757d' }}>© 2024 Food Smarts</Text>
            </View>
        </View>
    );
}
