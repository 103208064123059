import { View } from 'react-native'
import MediaQuery from "react-responsive";
import MenuGeneratorSmall from './MenuGenerator.sm'
import MenuGeneratorMedium from './MenuGenerator.md'
import MenuGeneratorLarge from './MenuGenerator.lg'

export default function MenuGenerator() {

    return (
        <View style={{ flex: 1 }}>
            <MediaQuery minWidth={0} maxWidth={640}>
                <MenuGeneratorSmall />
            </MediaQuery>
            <MediaQuery minWidth={640} maxWidth={1024}>
                <MenuGeneratorMedium />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <MenuGeneratorLarge />
            </MediaQuery>
        </View>
    )
}