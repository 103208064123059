import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

interface ActionButtonsProps {
  size: 'sm' | 'md' | 'lg';
  onMixItUp: () => void;
  onCheckout: () => void;
  estimatedPrice: string;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ size, onMixItUp, onCheckout, estimatedPrice }) => {
  const styles = getStyles(size);

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.mixButton} onPress={onMixItUp}>
        <Text style={styles.mixButtonText}>Mix It Up!</Text>
      </TouchableOpacity>

      <TouchableOpacity style={styles.checkoutButton} onPress={onCheckout}>
        <Text style={styles.checkoutButtonText}>Checkout {estimatedPrice}</Text>
      </TouchableOpacity>
    </View>
  );
};

const getStyles = (size: 'sm' | 'md' | 'lg') => StyleSheet.create({
  container: {
    flexDirection: size === 'sm' ? 'column' : 'row',
    gap: size === 'lg' ? 20 : 10,
    padding: size === 'lg' ? 20 : 10,
  },
  mixButton: {
    backgroundColor: '#d81e05',
    paddingHorizontal: size === 'lg' ? 40 : size === 'md' ? 20 : 30,
    paddingVertical: size === 'lg' ? 20 : size === 'md' ? 10 : 15,
    borderRadius: size === 'sm' ? 25 : 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: size === 'sm' ? 3 : 5,
    marginTop: size === 'lg' ? 20 : size === 'md' ? 10 : 0,
    width: size === 'sm' ? '100%' : 'auto',
  },
  mixButtonText: {
    fontSize: size === 'lg' ? 24 : size === 'md' ? 20 : 18,
    color: 'white',
    fontWeight: 'bold',
  },
  checkoutButton: {
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: '#2c3e50',
    paddingHorizontal: size === 'lg' ? 40 : size === 'md' ? 20 : 30,
    paddingVertical: size === 'lg' ? 20 : size === 'md' ? 10 : 15,
    borderRadius: size === 'sm' ? 25 : 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: size === 'sm' ? 3 : 5,
    marginTop: size === 'lg' ? 20 : size === 'md' ? 10 : 0,
    width: size === 'sm' ? '100%' : 'auto',
  },
  checkoutButtonText: {
    fontSize: size === 'lg' ? 24 : size === 'md' ? 20 : 18,
    color: '#2c3e50',
    fontWeight: 'bold',
  },
});

export default ActionButtons; 