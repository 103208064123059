import { View, Text, TouchableOpacity, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AppRootStackParamList } from '../../types';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

export default function Footer() {
    const navigation = useNavigation<NativeStackNavigationProp<AppRootStackParamList>>();

    const handleEmailPress = () => {
        Linking.openURL('mailto:contact@foodsmarts.ca');
    };

    return (
        <View style={{ backgroundColor: '#f8f9fa', padding: 15, marginTop: 'auto' }}>
            <View style={{ flexDirection: 'column', alignItems: 'center', gap: 20 }}>
                <TouchableOpacity onPress={() => navigation.navigate("Privacy")}>
                    <Text style={{ color: '#6c757d', fontSize: 14 }}>Privacy Policy</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={handleEmailPress}>
                    <Text style={{ color: '#6c757d', fontSize: 14 }}>Contact Us</Text>
                </TouchableOpacity>
                <Text style={{ color: '#6c757d', fontSize: 12 }}>© 2024 Food Smarts</Text>
            </View>
        </View>
    );
}
