import React from "react"
import { RecipeDetailScreenProps } from "../types";
import { Recipe } from "../apis/foodsmarts";
import ClosableComponent from "../pages/ClosableComponent";
import { useTheme } from "@react-navigation/native";
import {
    View,
    Image,
    Text,
    StyleSheet
} from "react-native";

function RecipeDetailScreen({ route, navigation }: RecipeDetailScreenProps) {
    const { colors } = useTheme();
    const styles = makeStyles(colors);

    const [recipe] = React.useState<Recipe>({
        ingredients: [
            { raw: "2 cups all-purpose flour" },
            { raw: "1 cup milk" },
            { raw: "2 large eggs" },
            { raw: "1 tsp vanilla extract" },
            { raw: "2 tbsp butter, melted" }
        ],
        instructions: [
            "Mix dry ingredients in a large bowl",
            "Whisk together wet ingredients in a separate bowl",
            "Combine wet and dry ingredients until just mixed",
            "Cook on a hot griddle until golden brown",
            "Serve warm with maple syrup"
        ],
        title: 'Classic Pancakes',
        prep_time: "10 minutes",
        cook_time: "15 minutes",
        yields: "8 servings",
        image: "https://placehold.co/400x300"
    });

    return (
        <ClosableComponent onClose={navigation.canGoBack() ? navigation.goBack : undefined}>
            {recipe && (
                <View style={styles.container}>
                    <View style={styles.imageContainer}>
                        <Image
                            source={{ uri: recipe.image || "https://placehold.co/400x300" }}
                            style={styles.image}
                            resizeMode="cover"
                        />
                    </View>

                    <View style={styles.content}>
                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Name</Text>
                            <Text style={styles.rowTitle}>{recipe.title || 'Untitled Recipe'}</Text>
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Ingredients</Text>
                            {recipe.ingredients?.map((ingredient, index) => (
                                <Text key={index} style={styles.rowTitle}>
                                    {ingredient.raw || 'Unknown ingredient'}
                                </Text>
                            ))}
                        </View>

                        <View style={styles.section}>
                            <Text style={styles.sectionHeader}>Instructions</Text>
                            {recipe.instructions?.map((instruction, index) => (
                                <Text key={index} style={styles.rowTitle}>
                                    {instruction || 'Missing instruction'}
                                </Text>
                            ))}
                        </View>
                    </View>
                </View>
            )}
        </ClosableComponent>
    );
}

const makeStyles = (colors: any) => StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {
        flex: 1,
        paddingHorizontal: 16,
    },
    imageContainer: {
        aspectRatio: 16 / 9,
        overflow: 'hidden',
        elevation: 2,
        shadowColor: colors.text,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        backgroundColor: colors.card,
    },
    image: {
        flex: 1,
    },
    section: {
        marginVertical: 16,
    },
    rowTitle: {
        fontSize: 16,
        color: colors.text,
        marginRight: 8,
        paddingVertical: 12,
    },
    sectionHeader: {
        fontSize: 20,
        fontWeight: 'bold',
        color: colors.text,
        backgroundColor: colors.background,
        paddingVertical: 12,
    }
});

export default RecipeDetailScreen;
