/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Menu } from '../models/Menu';
import type { MenuOptimizationParams } from '../models/MenuOptimizationParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MenuService {

    /**
     * Create Meal Plan
     * Create a meal plan
     * @returns Menu Successful Response
     * @throws ApiError
     */
    public static createMealPlanMenuPost({
        requestBody,
    }: {
        requestBody: MenuOptimizationParams,
    }): CancelablePromise<Menu> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/menu/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                422: `Validation Error`,
            },
        });
    }

}
