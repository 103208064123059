import { View } from 'react-native'
import MediaQuery from "react-responsive";
import FooterSmall from './Footer.sm'
import FooterMedium from './Footer'

export default function MenuGenerator() {

    return (
        <View style={{ flex: 1 }}>
            <MediaQuery minWidth={0} maxWidth={640}>
                <FooterSmall />
            </MediaQuery>
            <MediaQuery minWidth={640}>
                <FooterMedium />
            </MediaQuery>
        </View>
    )
}