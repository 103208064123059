import * as React from "react"
import Svg, { Path, Text, TSpan } from "react-native-svg"

interface LogoProps {
    width?: number
    height?: number
    [key: string]: any
}

const SvgComponent = ({ width = 269.41, height = 85.006, ...props }: LogoProps) => (
    <Svg
        width={width}
        height={height}
        viewBox="0 0 269.41 85.006"
        {...props}
    >
        <Path
            d="M75.776 8.823a8.327 8.327 0 0 1 .206 3.316c-.452 3.233-2.783 6.045-6.128 6.941a8.328 8.328 0 0 1-4.912-.178l-.491-.17-.349.386c-1.158 1.289-2.617 2.196-4.218 2.626-1.814.486-3.74.355-5.567-.38l-.56-.224-.36.483A8.277 8.277 0 0 1 49.77 24.4c-.13.05-.26.1-.392.144-.17.06-.341.115-.517.162-4.46 1.195-9.061-1.462-10.256-5.922-1.196-4.46 1.461-9.062 5.921-10.257a8.395 8.395 0 0 1 2.237-.278l.631.005.14-.616C48.372 3.92 50.84 1.173 54.135.29c3.215-.861 6.669.222 9.239 2.897l.381.397.504-.225a8.37 8.37 0 0 1 1.26-.459c4.46-1.194 9.06 1.462 10.256 5.923zm-10.86 11.715a10.231 10.231 0 0 1-4.627 2.718c-1.961.526-4.024.444-6.003-.232a9.796 9.796 0 0 1-4.108 2.897l2.674 9.98 20.068-5.378-2.66-9.93a9.888 9.888 0 0 1-5.344-.055z"
            fill="#d81e05"
            fillOpacity={1}
            strokeWidth={0.427584}
        />
        <Text
            x={-2.348}
            y={79.174}
            fontStyle={"normal"}
            fontVariant={"normal"}
            fontWeight={400}
            fontStretch={"normal"}
            fontSize={"61.6555px"}
            fontFamily={"Anton"}
            fill={"#d81e05"}
            fillOpacity={1}
            stroke={"none"}
            strokeWidth={1.54139}
            transform="scale(.93706 1.06716)"
        >
            <TSpan
                x={-2.348}
                y={79.174}
                strokeWidth={1.54139}
            >
                {"FoodSmarts"}
            </TSpan>
        </Text>
    </Svg>
)
export default SvgComponent