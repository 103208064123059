import '@expo/match-media'
import React from 'react';
import { NavigationContainer, DefaultTheme, createNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Text, View, TouchableHighlight } from 'react-native';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen.web'
import Logo from './svgs/Logo';
import MediaQuery from "react-responsive";

import { useFonts } from 'expo-font';
import Constants from 'expo-constants';
import { OpenAPI } from './apis/foodsmarts';
import RecipeDetailScreen from './screens/RecipeDetailScreen';
import MenuGeneratorScreen from './screens/MenuGenerator';
OpenAPI.BASE = Constants.expoConfig?.extra?.apiUrl
OpenAPI.WITH_CREDENTIALS = true
OpenAPI.CREDENTIALS = 'include'

const linking = {
  prefixes: [],
  enabled: true,
  config: {
    screens: {
      initialRouteName: 'Menu',
      Menu: 'menu',
      RecipeDetail: 'recipe/:id',
      Privacy: 'privacy',
    },
  }
};


export type RootStackParamList = {
  Menu: undefined;
  RecipeDetail: { id: string };
  Privacy: undefined;
};

const RootStack = createNativeStackNavigator<RootStackParamList>();
const navigationRef = createNavigationContainerRef<RootStackParamList>()


export default function App() {
  useFonts({
    'Anton': require('./assets/fonts/Anton-Regular.ttf'),
  });

  const MyTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: 'white'
    },
  };

  const header = () => (

    <View style={{ flex: 1, alignItems: 'center' }}>
      <MediaQuery minWidth={0} maxWidth={640}>
        <View style={{ marginVertical: 10 }}>
          <TouchableHighlight underlayColor={'undefined'} onPress={() => { navigationRef.navigate('Menu') }}>
            <Logo style={{ width: 200, height: 50 }} />
          </TouchableHighlight>
        </View>
      </MediaQuery>
      <MediaQuery minWidth={640} maxWidth={1024}>
        <View style={{ marginVertical: 10 }}>
          <TouchableHighlight underlayColor={'undefined'} onPress={() => { navigationRef.navigate('Menu') }}>
            <Logo style={{ width: 200, height: 50 }} />
          </TouchableHighlight>
        </View>
      </MediaQuery>
      <MediaQuery minWidth={1024}>
        <View style={{ marginVertical: 10 }}>
          <TouchableHighlight underlayColor={'undefined'} onPress={() => { navigationRef.navigate('Menu') }}>
            <Logo style={{ width: 200, height: 100 }} />
          </TouchableHighlight>
        </View>
      </MediaQuery>

    </View>
  );

  return (
    <View style={{ flexGrow: 1, flex: 1, backgroundColor: 'green' }}>
      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        fallback={<Text>Loading...</Text>}
        theme={MyTheme}
      >
        <RootStack.Navigator
          screenOptions={{
            title: 'Food Smarts',
            header: header
          }}
        >
          <RootStack.Screen
            name="Menu"
            component={MenuGeneratorScreen}
          />
          <RootStack.Screen
            name="RecipeDetail"
            component={RecipeDetailScreen}
            options={{
              header: () => null,
              title: 'Recipe',
              presentation: 'fullScreenModal'
            }} />
          <RootStack.Screen
            name="Privacy"
            options={{
              headerTitle: 'Privacy policy'
            }}
            component={PrivacyPolicyScreen}
          />
        </RootStack.Navigator>
      </NavigationContainer>
    </View>
  );
}
