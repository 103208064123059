import { View, Text, Image, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { HomeRootStackParamList } from '../../types'
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Footer from '../../components/Footer';
import ActionButtons from '../../components/ActionButtons';

type RecipeCardProps = {
    title: string;
    prepTime: string;
    cookTime: string;
    difficulty: string;
    imageUri: string;
    onPress: () => void;
}

const RecipeCard: React.FC<RecipeCardProps> = ({ title, prepTime, cookTime, difficulty, imageUri, onPress }) => (
    <TouchableOpacity
        style={{ width: '32%', backgroundColor: '#fff', padding: 20, borderRadius: 10, elevation: 3, alignItems: 'center', borderWidth: 1, borderColor: '#e0e0e0' }}
        activeOpacity={0.8}
        onPress={onPress}
        // @ts-ignore
        onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => (e.currentTarget.style.boxShadow = '0 0 0 2px #d81e05')}
        // @ts-ignore
        onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => (e.currentTarget.style.boxShadow = 'none')}
    >
        <Image
            source={{ uri: imageUri }}
            style={{ width: 300, height: 225, borderRadius: 8, marginBottom: 10 }}
        />
        <Text style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10, textAlign: 'left', alignSelf: 'stretch' }}>{title}</Text>
        <Text style={{ marginBottom: 5, textAlign: 'left', alignSelf: 'stretch' }}>Prep time: {prepTime}</Text>
        <Text style={{ marginBottom: 5, textAlign: 'left', alignSelf: 'stretch' }}>Cook time: {cookTime}</Text>
        <Text style={{ textAlign: 'left', alignSelf: 'stretch' }}>Difficulty: {difficulty}</Text>
    </TouchableOpacity>
)

export default function MenuGeneratorLarge() {
    const navigation = useNavigation<NativeStackNavigationProp<HomeRootStackParamList>>();

    return (
        <View style={{ flex: 1, flexGrow: 1 }}>
            <View style={{ alignItems: 'center' }}>
                <Text style={{ fontSize: 42, color: '#2c3e50', textAlign: 'center', padding: 20 }}>
                    Weekly Menu
                </Text>
                <View style={{ flexDirection: 'row', justifyContent: 'center', padding: 20, gap: 20 }}>
                    <RecipeCard
                        title="Spaghetti Carbonara"
                        prepTime="15 mins"
                        cookTime="20 mins"
                        difficulty="Medium"
                        imageUri="https://placehold.co/200x150"
                        onPress={() => navigation.navigate("RecipeDetail", { id: "1" })}
                    />
                    <RecipeCard
                        title="Grilled Chicken Salad"
                        prepTime="10 mins"
                        cookTime="15 mins"
                        difficulty="Easy"
                        imageUri="https://placehold.co/200x150"
                        onPress={() => navigation.navigate("RecipeDetail", { id: "1" })}
                    />
                    <RecipeCard
                        title="Vegetable Stir Fry"
                        prepTime="20 mins"
                        cookTime="15 mins"
                        difficulty="Easy"
                        imageUri="https://placehold.co/200x150"
                        onPress={() => navigation.navigate("RecipeDetail", { id: "1" })}
                    />
                </View>
                <ActionButtons
                    size="lg"
                    onMixItUp={() => {/* Handle mix it up */ }}
                    onCheckout={() => {/* Handle checkout */ }}
                    estimatedPrice="$45-60"
                />
            </View>
            <Footer />
        </View>
    )
}
